<template>
    <div class="project">
        <div class="project-header">
            <div class="project-option">
                <button class="project-option-btn" @click="handleCreateProject">
                    创建项目
                </button>
                <button class="project-option-btn" @click="handlePersonGoal">
                    个人交付目标
                </button>
            </div>
            <project-search
                ref="projectSearch"
                :projectNumber="pager.total"
                @refresh-project-list="refresh"
            ></project-search>
        </div>
        <div class="project-content">
            <div class="project-content-left">
                <div class="project-list" v-loading="projectListLoading">
                    <template v-if="projectList.length > 0">
                        <project-item
                            :project="project"
                            :projectIndex="index"
                            v-for="(project, index) in projectList"
                            :key="project.id"
                            :ref="'project_' + index"
                            @edit-project-cb="editProjectCb(...arguments, index)"
                        ></project-item>
                    </template>
                    <div class="empty" v-else>
                        <span class="empty-img"></span>
                        <p class="empty-data">暂无数据</p>
                    </div>
                </div>
                <el-pagination
                    :current-page="pager.current"
                    :page-sizes="[20, 30, 50]"
                    :page-size="pager.size"
                    class="project-list-pagination"
                    layout="prev, pager, next, slot, total, sizes"
                    :total="pager.total"
                    v-if="projectList.length > 0"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                >
                    <span class="pagination-text">
                        <span>
                            前往
                            <el-input
                                v-model="pagerJump"
                                @keyup.enter.native="handlePagerJump(pagerJump)"
                            ></el-input>
                            页
                        </span>
                        <span @click="handlePagerJump(pagerJump)"> 跳转 </span>
                    </span>
                </el-pagination>
            </div>
            <div class="project-content-right">
                <!-- <button class="project-right-btn" @click="handleCreateProject">
                    创建项目
                </button>
                <button class="project-right-btn" @click="handlePersonGoal">
                    个人交付目标
                </button> -->
                <project-filter
                    v-loading="projectListLoading"
                    ref="filterArea"
                    :filterObj="resultFilterMap"
                    :filterTransParams="resultFilterSelect"
                ></project-filter>

                <!-- 项目最新动态 -->
                <div class="project-news">
                    <h2 class="title">最新动态</h2>
                    <div class="news-container" v-loading="tendLoading">
                        <ul>
                            <li
                                class="news-item"
                                v-for="(item, index) in trendList"
                                :key="'trend_' + index"
                            >
                                <avatar
                                    class="pic"
                                    size="md"
                                    :src="item.avatarUrl"
                                    :userId="item.userId"
                                    :enableCard="true"
                                    :enableLink="true"
                                ></avatar>
                                <div class="new-content">
                                    <p>
                                        <span
                                            class="name ell"
                                            :title="
                                                item.realName + '@' + item.nickname
                                            "
                                        >
                                            <span class="real-name">{{
                                                item.realName
                                            }}</span>
                                            @{{ item.nickname }}
                                        </span>
                                        <span class="time">{{ item.created }}</span>
                                    </p>
                                    <p v-html="computedDescription(item)"></p>
                                </div>
                            </li>
                            <div class="empty" v-if="trendList.length == 0">
                                暂无数据
                            </div>
                            <div
                                class="news-bottom"
                                v-if="showMore"
                                @click="handleShowMore"
                            >
                                <span class="news-show-more"
                                    >加载更多<i class="el-icon-d-arrow-right"></i
                                ></span>
                            </div>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <create-project-dialog
            ref="createProjectDialog"
            @refresh-project-list="clearAndRefresh"
            @aboutThisProject="aboutThisProject"
        ></create-project-dialog>
        <el-tooltip
            effect="black"
            placement="top"
            ref="tooltip"
            content="哈哈哈"
        ></el-tooltip>

        <!-- 编辑项目 -->
        <edit-project
            v-if="isEditProgram"
            :projectParams="projectParams"
            @edit-program-finish="editProgramFinish"
        >
        </edit-project>

        <!-- 项目交付目标弹窗 -->
        <add-program-goal
            v-if="isAddGoal"
            :goalParams="goalParams"
            :baseData="baseData"
            @add-goal-finish="addGoalFinish"
        ></add-program-goal>
    </div>
</template>

<script>
import ProjectSearch from "./component/project-search.vue";
import ProjectItem from "./component/project-item.vue";
import Avatar from "#/component/common/avatar.vue";
import ProjectFilter from "./component/project-filter.vue";
import CreateProjectDialog from "./component/create-project-dialog.vue";
import EditProject from "../program-detail/component/edit-program.vue";
import AddProgramGoal from "../program-detail/component/add-program-goal.vue";

import ProjectService from "#/js/service/projectService.js";
import projectDetailService from "#/js/service/projectDetailService.js";

const defaultSearchLabels = {
    keyword: {
        name: "keyword",
        title: "关键词",
        value: "",
    },
    customer: {
        name: "customer",
        title: "客户",
        value: "",
    },
    jobCatogery: {
        name: "jobCatogery",
        title: "职位类别",
        value: "",
    },
    creator: {
        name: "creator",
        title: "创建者",
        value: "",
    },
    createDate: {
        name: "createDate",
        title: "创建时间",
        value: "",
    },
};
export default {
    name: 'ProjectList',
    componentName: "ProjectList",
    components: {
        ProjectSearch,
        ProjectItem,
        Avatar,
        ProjectFilter,
        CreateProjectDialog,
        EditProject,
        AddProgramGoal,
    },
    data() {
        return {
            bindJobIds: [],
            isAddGoal: false, // 交付目标弹窗是否出现
            projectId: undefined,
            // 添加目标的参数
            goalParams: {
                type: "add",
                businessType: "project",
                projectName: "",
            },
            baseData: {},

            projectList: [],
            pager: {
                current: 1,
                size: 20,
                total: 0,
            },
            pagerJump: 0,
            searchLabels: JSON.parse(JSON.stringify(defaultSearchLabels)),
            searchParams: {
                locations: [], //结果筛选-城市
                degree: "", //结果筛选-学历，未知学历/高中及以下
                minDegree: "", //结果筛选-学历，高中以上学历
                yearOfExperienceRegions: [], //结果筛选-工作经验
                skillStandardTags: [], //结果筛选-技能
                workExperienceCompaniesName: [], //结果筛选-公司
                recommendStatus: [], //结果筛选-公司
            },
            searchConditionList: [],
            resultFilterMap: {},
            resultFilterSelect: {},
            lightWordMap: {
                keywords: "",
                title: "",
                companyName: "",
            },
            projectListLoading: false,
            isFromSort: false,
            isShowCompanyNameFilter: true,
            projectParams: {},
            isEditProgram: false,

            tendLoading: false,
            trendList: [],
            trend: {
                Start: 1,
                Take: 5,
                total: 0,
            },
            // sizeStep: 5,
        };
    },
    filters: {
        formatAvatar(url) {
            if(NODE_ENV && (NODE_ENV == 'dev' || NODE_ENV == 'rc')) {
                url = url.replace('www.rc.liebide.com', 'portalapi.rc.liebide.com');
            }
            return url;
        }
    },
    created() {},
    mounted() {
        if(!this.isAdministrator && !this.isProject){
            shortTips("权限不足，请联系管理员")
            setTimeout(() => {
                location.href = "/Headhunting/#/";
            }, 2000)
        }

        this.resultFilterSelect = this.$refs.projectSearch.searchLabels;

        let projectSearch = this.$refs.projectSearch;
        this.$on("add-search-param", (obj) => {
            console.log(obj);
            if (obj.value == "") {
                projectSearch.removeLabel(obj);
            } else {
                if (obj.title == "customer") {
                    projectSearch.projectSearchForm["customerIds"] = obj.data;
                    projectSearch.$refs.customerSelect.customerId = obj.value;
                } else {
                    if (obj.title == "jobSeries") {
                        projectSearch.$refs.jobSeriesSelect.jobSeriesName =
                            obj.value;
                    }
                    projectSearch.$set(
                        projectSearch.projectSearchForm,
                        obj.title,
                        obj.data
                    );
                }
                projectSearch.searchLabels[obj.title].value = obj.data;
                projectSearch.searchLabels[obj.title].textValue = obj.value;
                projectSearch.addParams();
            }
            this.resultFilterSelect = projectSearch.searchLabels;
        });
        this.search();
        this.getProjectTrendList();
    },
    computed: {
        showMore() {
            return this.trend.total > (this.trend.Start - 1) * this.trend.Take;
        },
        isAdministrator() {
            return this.$store.state.user.userInfo.isAdministrator || false;
        },
        isProject() {
            return (this.$store.state.user.userInfo.privilegeCodeList || []).includes('Project');
        }
    },
    methods: {
        aboutThisProject(projectId, projectName) {
            // console.log(projectId,projectName);
            this.projectId = projectId;
            this.goalParams.projectName = projectName.replace("$#$", "-");
            // console.log(this.goalParams);
            // 创建项目成功之后，刷新项目主页最新动态的列表
            this.getProjectTrendList();
        },
        // 添加项目交付目标
        addGoal() {
            this.goalParams = {
                type: "add",
                businessType: "project",
                porjectId: this.projectId,
                projectName: this.goalParams.projectName,
            };
            this.isAddGoal = true;
        },
        addGoalFinish() {
            this.isAddGoal = false;
        },

        clearAndRefresh(baseData, bindJobIds) {
            // console.log(baseData);
            console.log(bindJobIds);
            this.baseData = baseData;
            this.bindJobIds = bindJobIds;
            this.$refs.projectSearch.handleClearSearch();
        },
        // 获取项目动态
        getProjectTrendList() {
            const params = {
                Start: (this.trend.Start - 1) * this.trend.Take,
                Take: this.trend.Take,
            };
            this.tendLoading = true;
            ProjectService.getProjectTrend(params)
                .then((res) => {
                    res.list.forEach((el) => {
                        el.created = el.created.split(" ")[0];
                    });
                    if (this.trend.Start == 1) {
                        this.trendList = res.list;
                    } else {
                        this.trendList = [...this.trendList, ...res.list];
                    }
                    this.trend.total = res.total;
                })
                .finally(() => {
                    this.tendLoading = false;
                });
        },
        refresh() {
            this.resultFilterSelect = this.$refs.projectSearch.searchLabels;
            this.pager.current = 1;
            this.pagerJump = 0;
            // this.search();

            let searchJson = Object.assign(
                {},
                this.$refs.projectSearch.projectSearchForm
            );
            searchJson.take = this.pager.size;
            searchJson.start = (this.pager.current - 1) * this.pager.size;
            this.projectListLoading = true;
            ProjectService.getProjectList(searchJson)
                .then((res) => {
                    this.pager.total = res.total;
                    this.projectList = res.list;
                    this.resultFilterMap = res.categoryGroupedItemsDic;
                    // document.documentElement.scrollTop = 0;
                    document.querySelector('.project').scrollTop = 0;
                    let isCreadProject =
                        sessionStorage.getItem("isCreadProject");
                    if (isCreadProject && isCreadProject == "true") {
                        // this.addGoal();
                        // 创建项目时有绑定职位才弹出项目交付目标弹窗，没有绑定不弹出
                        if(this.bindJobIds.length > 0) {
                            this.addGoal();
                        }
                        sessionStorage.setItem("isCreadProject", "false");
                    }
                })
                .finally(() => {
                    this.projectListLoading = false;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        search(silent) {
            let searchJson = Object.assign(
                {},
                this.$refs.projectSearch.projectSearchForm
            );
            if (!silent) {
                this.pager.current = 1;
            }
            searchJson.take = this.pager.size;
            searchJson.start = (this.pager.current - 1) * this.pager.size;
            this.projectListLoading = true;
            ProjectService.getProjectList(searchJson)
                .then((res) => {
                    this.pager.total = res.total;
                    this.projectList = res.list;
                    this.resultFilterMap = res.categoryGroupedItemsDic;

                    // document.documentElement.scrollTop = 0;
                    document.querySelector('.project').scrollTop = 0;
                })
                .finally(() => {
                    this.projectListLoading = false;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        handleSizeChange(val) {
            this.pager.size = val;
            this.pager.current = 1;
            this.search(true);
        },
        handleCurrentChange(current) {
            this.pager.current = current;
            this.search(true);
        },
        handlePagerJump(pagerJump) {
            pagerJump = Number.parseInt(pagerJump);
            if (
                pagerJump > 0 &&
                pagerJump <= Math.ceil(this.pager.total / this.pager.size)
            ) {
                this.handleCurrentChange(pagerJump);
            }
        },
        handleCreateProject() {
            this.$refs.createProjectDialog.show();
        },
        // 项目详情页面
        // handleViewProject() {
        //     const projectId = `ad1603e5-dfb5-4244-b3f6-d754c8523435`;
        //     const projectDetailUrl = `/Headhunting/MyCompany.html#/ProjectDetail/${projectId}`;
        //     window.open(projectDetailUrl);
        // },
        // 个人交付目标
        handlePersonGoal() {
            // TODO: 回头看有什么参数要传过来，再完善一下。
            const goalUrl = `/Headhunting/MyCompany.html#/myGoal`;
            // const goalUrl = `/#/myGoal`;
            window.open(goalUrl);
        },

        // 项目编辑回调
        editProjectCb(projectId, idx) {
            if (!projectId) return;
            projectDetailService
                .getProjectDetailInfo({ id: projectId })
                .then((res) => {
                    this.projectParams = {
                        projectId: projectId,
                        ...res,
                    };
                    this.isEditProgram = true;
                    this.$refs["project_" + idx][0].editLoading = false;
                });
        },

        // 项目编辑完成的回调
        editProgramFinish(isEdit) {
            this.isEditProgram = false;
            // 刷新页面获取数据
            if (isEdit) {
                this.search(true);
            }
        },

        computedDescription(item) {
            // 1001：将职位 产品经理  添加到 菜鸟-杭州-产运项目
            // 1004：创建了 菜鸟-杭州-产运 项目
            let sDescribe = "";
            // console.log(item);
            // console.log(item.activityStreamType);
            // console.log(item.extensionProperties.projectName);
            if (item.projectName) {
                const itemProjectName = item.projectName.replace("$#$", "-");
                if (item.activityStreamType == 1001) {
                    // sDescribe = `将职位 <a class="green" href="/Headhunting/MyCompany.html#/Job/${item.extensionProperties.jobId}" target="_blank" title="${item.extensionProperties.jobTitle}">${item.extensionProperties.jobTitle}</a> 添加到 <a class="green" href="/Headhunting/MyCompany.html#/ProjectDetail/${item.projectId}" target="_blank" title="${itemProjectName}">${itemProjectName}</a> 项目`;
                    sDescribe = `将职位 <a class="green" href="/#/Job/${item.extensionProperties.jobId}" target="_blank" title="${item.extensionProperties.jobTitle}">${item.extensionProperties.jobTitle}</a> 添加到 <a class="green" href="/#/ProjectDetail/${item.projectId}" target="_blank" title="${itemProjectName}">${itemProjectName}</a> 项目`;
                } else if (item.activityStreamType == 1004) {
                    // sDescribe = `创建了 <a class="green" href="/Headhunting/MyCompany.html#/ProjectDetail/${item.projectId}" target="_blank" title="${itemProjectName}">${itemProjectName}</a> 项目`;
                    sDescribe = `创建了 <a class="green" href="/#/ProjectDetail/${item.projectId}" target="_blank" title="${itemProjectName}">${itemProjectName}</a> 项目`;
                }
                return sDescribe;
            }
        },
        // 点击加载更多
        handleShowMore() {
            this.trend.Start++;
            // this.trend.Take = this.trend.Take + this.sizeStep;
            this.getProjectTrendList();
        },
    },
};
</script>

<style lang="scss" scope>
@media screen and (max-width: 1400px) {
    .project-content-left {
        width: calc(100% - 370px) !important;
    }
    .project-content-right {
        width: 350px !important;
    }
}
@media screen and (min-width: 1400px) and (max-width: 1680px) {
    .project-content-left {
        width: calc(100% - 430px) !important;
    }
    .project-content-right {
        width: 410px !important;
    }
}
.project {
    padding: 20px 20px 30px;
    height: 100%;
    overflow-y: auto;
    &-header {
        background-color: #fff;
        border-radius: 8px;
        .project-option {
            padding: 0 20px;
            height: 60px;
            border-bottom: 1px solid #EEEEEE;
            display: flex;
            align-items: center;
            // justify-content: end;
            justify-content: flex-end;
            &-btn {
                width: 150px;
                height: 36px;
                line-height: 36px;
                background-color: #38bc9d;
                border-radius: 4px;
                color: #fff;
                font-size: 14px;
                margin-right: 30px;

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
    
    &-content {
        display: flex;
        &-left {
            width: calc(100% - 470px);
            background: #fff;
            border-radius: 8px;
            padding: 0 20px 20px;
        }

        &-right {
            width: 450px;
            margin-left: 20px;

            .el-loading-spinner {
                display: none;
            }

            // .project-right-btn {
            //     display: block;
            //     width: 100%;
            //     height: 50px;
            //     background-color: #fff;
            //     border: 1px solid $primary;
            //     color: $primary;
            //     margin-bottom: 10px;

            //     &:last-child {
            //         margin-bottom: 20px;
            //     }
            // }

            .project-news {
                width: 100%;
                // margin-top: 32px;
                margin-top: 20px;
                background: #fff;
                border-radius: 8px;
                padding: 20px;
                .title {
                    font-size: 16px;
                    font-weight: 400;
                    color: #333333;
                    line-height: 36px;
                    &::before {
                        content: "";
                        display: inline-block;
                        width: 8px;
                        height: 20px;
                        background: #38bc9d;
                        margin-right: 10px;
                        vertical-align: middle;
                    }
                }
                .news-container {
                    background: #fff;
                    padding-bottom: 20px;
                    ul {
                        margin-bottom: 0px;
                    }
                }
                .news-item {
                    width: 100%;
                    height: auto;
                    display: flex;
                    flex-flow: row nowrap;
                    box-sizing: border-box;
                    margin: 12px 0;
                    .pic {
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;
                        overflow: hidden;
                        margin-right: 11px;
                    }
                    .new-content {
                        flex: 1;
                        max-width: 260px;
                        > p {
                            font-size: 14px;
                            font-weight: 400;
                            color: #666666;
                            line-height: 17px;

                            .gray {
                                color: #b9b9b9;
                            }
                            .green {
                                color: #38bc9d;
                                white-space: pre-line;
                                word-break: break-all;
                            }
                            .name {
                                display: inline-block;
                                color: #b9b9b9;
                                max-width: 104px;
                                .real-name {
                                    color: black;
                                }
                            }
                            .time {
                                color: #b9b9b9;
                                padding-left: 18px;
                                font-size: 14px;
                                // display: inline-block;
                                float: right;
                            }
                        }
                    }
                }
                .empty {
                    text-align: center;
                    padding: 30px 0 10px;
                }
                .news-bottom {
                    height: 28px;
                    background: #ffffff;
                    border: 1px solid #cccccc;
                    box-sizing: border-box;
                    text-align: center;
                    line-height: 26px;
                    cursor: pointer;
                    .news-show-more {
                        color: #ccc;
                        font-size: 12px;
                    }
                }
            }
        }
    }

    &-list {
        min-height: 400px;
    }
}
.project-list-pagination.el-pagination {
    margin: 40px 0 20px 0;
    padding: 0;
    font-weight: normal;
    .btn-next,
    .btn-prev {
        height: 38px;
        border: 1px solid;
    }
    .btn-prev {
        border-right: none;
        border-radius: 4px 0 0 4px;
    }
    .btn-next {
        border-left: none;
        border-radius: 0 4px 4px 0;
    }
    .el-pagination__sizes .el-input {
        width: 87px;
        .el-input__inner {
            color: #666;
            .el-select__caret {
                color: #999;
            }
        }
    }
    .el-pager {
        border-top: 1px solid;
        border-bottom: 1px solid;
        li {
            min-width: 36px;
            height: 36px;
            line-height: 36px;
            font-size: 14px;
        }
    }
    .btn-next,
    .btn-prev,
    .el-pager {
        border-color: #dddddd;
    }
    .el-input__inner,
    .el-pagination__total,
    .el-pagination__jump {
        height: 38px;
        line-height: 38px;
        font-size: 14px;
    }
    .pagination-text {
        color: #999;
        span {
            height: 38px;
            line-height: 38px;
            font-size: 14px;
            .el-input {
                width: 48px;
                margin: 0 5px;
                &__inner {
                    height: 38px;
                    line-height: 38px;
                    padding: 0 5px;
                }
            }
            &:nth-of-type(2) {
                margin-left: 5px;
                color: $primary;
                cursor: pointer;
            }
        }
    }
    .el-pagination__total,
    .el-pagination__sizes,
    .pagination-text {
        float: right;
    }
}
.empty {
    min-height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .empty-img {
        display: inline-block;
        background: url("~@src/assets/images/home/icon_no_data.svg") no-repeat;
        background-size: contain;
        width: 170px;
        height: 160px;
    }
    .empty-data {
        font-size: 14px;
        margin-bottom: 0px;
    }
}
</style>