<template>
    <div
        v-if="enableShow"
        class="filter-item"
    >
        <div class="item-header">
            <span
                class="item-title"
                :class="titleIcon"
            >
                {{title | headerTextFilter}}
            </span>
        </div>
        <div class="item-content">
            <div class="item-list">
                <div
                    v-for="(item, index) in multipleSelectValue"
                    :key="index"
                    class="item-wrap"
                    :class="{'item-active': item.isSelect}"
                    @click="handleCategoryFilter(item)"
                >
                    <span class="item-name" :title="item.itemText">
                        {{item.itemText}}
                    </span>
                    <span class="item-value">
                        ({{item.count | currency('', 0)}})
                    </span>
                </div>
            </div>
            <el-select
                filterable
                v-if="enableShowSelect"
                class="item-filter-select"
                :default-first-option="true"
                popper-class="filter-select-popper"
                :placeholder="handleFilterPlaceholder(title)"
                v-model="filterItemValue"
            >
                <el-option
                    class="temp-list"
                    v-for="(item, index) in tempList"
                    :key="index"
                    :label="item.itemText"
                    :value="item.itemValue"
                    @click.stop.native="handleCategoryFilter(item)"
                >
                    <span class="temp-name" :title="item.itemText">
                        {{item.itemText}}
                    </span>
                    <span class="temp-value">
                        ({{item.count}})
                    </span>
                </el-option>
            </el-select>
        </div>
    </div>
</template>
<script>
import emitter from '#/js/mixins/emitter.js';
import moment from 'moment';
const headerTextMap = {
    locations: '城市(支持多选)',
    jobSeries: '职位类别(支持多选)',
    customer: '客户(支持多选)'
};
const iconMap = {
    locations: 'location-icon',
    jobSeries: 'job-serie-icon',
    customer: 'company-icon'
};
const filterPlaceholder = {
    locations: "输入城市名称",
    jobSeries: '输入职位类别名称1',
    customer: '输入客户名称'
};

export default {
    mixins: [emitter],
    props: {
        sortList: {
            type: Array,
            default: () => {
                return []
            }
        },
        currentParams: {
            type: Object
        },
        multiple: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default:''
        },
    },
    data(){
        return{
            filterItemValue: "",
            selectLabel: []
        }
    },
    computed:{
        selectList() {
            return this.currentParams[this.title] ? this.currentParams[this.title].value : [];
        },
        enableShow(){
            return this.multipleSelectValue.length > 0;
        },
        titleIcon(){
            return iconMap[this.title];
        },
        multipleSelectValue(){
            let title = this.title,
                list = [],
                selects = this.selectList,
                firstList = [];
            this.selectLabel = [];

            list = this.sortList.length > 5 ? this.sortList.slice(0, 5) : [].concat(this.sortList);
            if(!this.multiple && this.selectList) {
                this.sortList.forEach((item, index) => {
                    if(item.itemValue == this.selectList) {
                        item.isSelect = true;
                        this.selectLabel = item;
                        if(index >= 5) {
                            list.push(item);
                        }
                    } else {
                        item.isSelect = false;
                    }
                });
            } else if(this.selectList.length > 0){
                firstList = this.sortList.slice(0, 5);
                list = [];
                this.sortList.forEach(item => {
                    item.isSelect = false;
                    for(let i = 0; i < selects.length; i++) {
                        if(selects[i] == item.itemValue) {
                            item.isSelect = true;
                            this.selectLabel.push(item);
                            list.push(item);
                            break;
                        }
                    }
                });
                for(let j = 0; j < firstList.length; j++) {
                    let hasSelect = list.some(item => {
                        return item.itemValue == firstList[j].itemValue;
                    })
                    if(!hasSelect) {
                        list.push(firstList[j]);
                    }
                }
                list.sort((item1,item2) => {
                    return Number(item2.count) - Number(item1.count);
                })
            }
            return list;
        },
        tempList() {
            let title = this.title;
            if(this.sortList.length <= 5) {
                return [];
            }else {
                let list = [];
                if(this.multiple && this.selectList.length > 0) {
                    let selects = this.selectList,
                        selectsLastIndex = this.selectList.length - 1,
                        hasSelectItem = false;
                    this.sortList.map((item, index) => {
                        hasSelectItem = this.selectList.some(select => {
                            return item.itemValue == select;
                        })
                        if(!hasSelectItem && index >= 5) {
                            list.push(item);
                        }
                    })
                } else if(!this.multiple && this.selectList) {
                    this.sortList.map((item, index) => {
                        if(item.itemValue != this.selectList && index >= 5) {
                            list.push(item);
                        }
                    })
                } else {
                    list = this.sortList.slice(5);
                }
                return list;
            }
        },
        enableShowSelect(){
            return this.tempList && this.tempList.length > 0;
        }
    },
    filters: {
        headerTextFilter(type) {
            return headerTextMap[type];
        }
    },
    mounted() {
    },
    methods: {
        handleFilterPlaceholder(title) {
            return filterPlaceholder[title];
        },
        handleCategoryFilter(item) {
            this.filterItemValue = '';
            let currentActiveVal = "",
                paramValue = [],
                paramText = [];
            if(this.multiple) {
                currentActiveVal = this.selectLabel;
                if(item.isSelect) {
                    let index = currentActiveVal.findIndex(select => {
                        return select.itemValue === item.itemValue;
                    });
                    currentActiveVal.splice(index, 1);
                } else {
                    if(currentActiveVal.length >= 5) {
                        shortTips('同一个筛选项最多选择5项');
                        return false;
                    }
                    item.isSelect = true;
                    currentActiveVal.push(item);
                }
            } else {
                if(item.isSelect) {
                    currentActiveVal = "";
                } else {
                    currentActiveVal = item;
                }
            }
            if(currentActiveVal && this.multiple) {
                currentActiveVal.forEach(item => {
                    paramValue.push(item.itemValue);
                    paramText.push(item.itemText);
                });
            } else if(currentActiveVal && !this.multiple) {
                paramValue = currentActiveVal.itemValue;
                paramText.push(currentActiveVal.itemText);
            }

            this.dispatch('ProjectList', 'add-search-param', {
                title: this.title,
                value: paramText.join(", "),
                data: paramValue
            });
        }
    }
}
</script>

<style lang="scss" scope>
.filter-item {
    border-top: 1px solid #ddd;
    padding-bottom: 10px;
    .item-header {
        margin-top: 16px;
        height: 28px;
        position: relative;
        .item-title {
            height: 28px;
            line-height: 28px;
            color: #333;
            font-size: 14px;
            padding-left: 24px;
            position: relative;
            display: inline-block;
            &::before {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                margin: auto;
                width: 16px;
                height: 16px;
                content: '';
            }
        }
        span.degree-icon {
            &::before {
                width: 18px;
                height: 18px;
                background-size: contain;
                background: url('~@src/assets/images/icon/filter-area/degree.png') no-repeat;
            }
        }
        span.location-icon {
            &::before {
                background: url('~@src/assets/images/icon/filter-area/location.png') no-repeat;
            }
        }
        span.experience-icon {
            &::before {
                background: url('~@src/assets/images/icon/filter-area/experience.png') no-repeat;
            }
        }
        span.skill-icon {
            &::before {
                background: url('~@src/assets/images/icon/filter-area/skill.png') no-repeat;
            }
        }
        span.company-icon {
            &::before {
                background: url('~@src/assets/images/icon/filter-area/company.png') no-repeat;
            }
        }
        span.customer-icon {
            &::before {
                background: url('~@src/assets/images/icon/filter-area/customer.png') no-repeat;
            }
        }
        span.job-serie-icon {
            &::before {
                width: 8px;
                height: 16px;
                background: url('~@src/assets/images/icon/filter-area/job-serie.png') no-repeat;
                background-size: contain;
            }
        }
        
        span.title-icon {
            &::before {
                background: url('~@src/assets/images/icon/filter-area/title.png') no-repeat;
            }
        }
        span.status-icon {
            &::before {
                background: url('~@src/assets/images/icon/filter-area/status.png') no-repeat;
            }
        }
        .city-tip {
            display: inline-block;
            position: absolute;
            top: 0;
            right: 0;
            background: #fa765d;
            padding: 0 8px;
            font-size: 12px;
            color: #fff;
            line-height: 28px;
            height: 28px;
            border-radius: 2px;
            .city-tip-close {
                font-size: 16px;
                cursor: pointer;
            }
            .city-tip-prend {
                margin-left: -6px;
                position: absolute;
                display: block;
                width: 0;
                height: 0;
                top: 9px;
                left: 1px;
                width: 5px;
                height: 10px;
                border: 5px solid transparent;
                border-left: none;
                border-right-color: #fa765d;
            }
        }
    }
    .item-content {
        .item-list {
            .item-wrap {
                display: inline-block;
                height: 28px;
                line-height: 28px;
                cursor: pointer;
                color: #666;
                padding: 0 10px;
                span {
                    display: inline-block;
                    height: 28px;
                    line-height: 28px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
                span.item-name {
                    max-width: 125px;
                }
                span.item-value {
                    max-width: 70px;
                }
            }
            .item-active {
                color: #38BC9C;
                background-color: #e7f7f3;
                border-radius: 25px;
            }
        }
        .item-filter {
            margin-top: 3px;

            &-select.el-select {
                width: 100%;
                .el-input--suffix {
                    .el-input__inner {
                        padding: 0 10px;
                        height: 32px;
                        line-height: 32px;
                    }
                    .el-input__suffix {
                        display: none;
                    }
                }
            }

        }
    }
}
</style>
<style lang="scss">
.filter-select-popper.el-select-dropdown {
    .temp-list {
        padding: 0 8px;
        &:hover {
            background: #e7f7f3;
            color: #38BC9C;
        }
        span {
            display: inline-block;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
        span.temp-name {
            max-width: 185px;
        }
        span.temp-value {
            max-width: 70px;
        }
    }
}
</style>
