var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "project" },
    [
      _c(
        "div",
        { staticClass: "project-header" },
        [
          _c("div", { staticClass: "project-option" }, [
            _c(
              "button",
              {
                staticClass: "project-option-btn",
                on: { click: _vm.handleCreateProject },
              },
              [_vm._v("\n                创建项目\n            ")]
            ),
            _c(
              "button",
              {
                staticClass: "project-option-btn",
                on: { click: _vm.handlePersonGoal },
              },
              [_vm._v("\n                个人交付目标\n            ")]
            ),
          ]),
          _c("project-search", {
            ref: "projectSearch",
            attrs: { projectNumber: _vm.pager.total },
            on: { "refresh-project-list": _vm.refresh },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "project-content" }, [
        _c(
          "div",
          { staticClass: "project-content-left" },
          [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.projectListLoading,
                    expression: "projectListLoading",
                  },
                ],
                staticClass: "project-list",
              },
              [
                _vm.projectList.length > 0
                  ? _vm._l(_vm.projectList, function (project, index) {
                      return _c("project-item", {
                        key: project.id,
                        ref: "project_" + index,
                        refInFor: true,
                        attrs: { project: project, projectIndex: index },
                        on: {
                          "edit-project-cb": function ($event) {
                            return _vm.editProjectCb(...arguments, index)
                          },
                        },
                      })
                    })
                  : _c("div", { staticClass: "empty" }, [
                      _c("span", { staticClass: "empty-img" }),
                      _c("p", { staticClass: "empty-data" }, [
                        _vm._v("暂无数据"),
                      ]),
                    ]),
              ],
              2
            ),
            _vm.projectList.length > 0
              ? _c(
                  "el-pagination",
                  {
                    staticClass: "project-list-pagination",
                    attrs: {
                      "current-page": _vm.pager.current,
                      "page-sizes": [20, 30, 50],
                      "page-size": _vm.pager.size,
                      layout: "prev, pager, next, slot, total, sizes",
                      total: _vm.pager.total,
                    },
                    on: {
                      "size-change": _vm.handleSizeChange,
                      "current-change": _vm.handleCurrentChange,
                    },
                  },
                  [
                    _c("span", { staticClass: "pagination-text" }, [
                      _c(
                        "span",
                        [
                          _vm._v(
                            "\n                        前往\n                        "
                          ),
                          _c("el-input", {
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.handlePagerJump(_vm.pagerJump)
                              },
                            },
                            model: {
                              value: _vm.pagerJump,
                              callback: function ($$v) {
                                _vm.pagerJump = $$v
                              },
                              expression: "pagerJump",
                            },
                          }),
                          _vm._v(
                            "\n                        页\n                    "
                          ),
                        ],
                        1
                      ),
                      _c(
                        "span",
                        {
                          on: {
                            click: function ($event) {
                              return _vm.handlePagerJump(_vm.pagerJump)
                            },
                          },
                        },
                        [_vm._v(" 跳转 ")]
                      ),
                    ]),
                  ]
                )
              : _vm._e(),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "project-content-right" },
          [
            _c("project-filter", {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.projectListLoading,
                  expression: "projectListLoading",
                },
              ],
              ref: "filterArea",
              attrs: {
                filterObj: _vm.resultFilterMap,
                filterTransParams: _vm.resultFilterSelect,
              },
            }),
            _c("div", { staticClass: "project-news" }, [
              _c("h2", { staticClass: "title" }, [_vm._v("最新动态")]),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.tendLoading,
                      expression: "tendLoading",
                    },
                  ],
                  staticClass: "news-container",
                },
                [
                  _c(
                    "ul",
                    [
                      _vm._l(_vm.trendList, function (item, index) {
                        return _c(
                          "li",
                          { key: "trend_" + index, staticClass: "news-item" },
                          [
                            _c("avatar", {
                              staticClass: "pic",
                              attrs: {
                                size: "md",
                                src: item.avatarUrl,
                                userId: item.userId,
                                enableCard: true,
                                enableLink: true,
                              },
                            }),
                            _c("div", { staticClass: "new-content" }, [
                              _c("p", [
                                _c(
                                  "span",
                                  {
                                    staticClass: "name ell",
                                    attrs: {
                                      title:
                                        item.realName + "@" + item.nickname,
                                    },
                                  },
                                  [
                                    _c("span", { staticClass: "real-name" }, [
                                      _vm._v(_vm._s(item.realName)),
                                    ]),
                                    _vm._v(
                                      "\n                                        @" +
                                        _vm._s(item.nickname) +
                                        "\n                                    "
                                    ),
                                  ]
                                ),
                                _c("span", { staticClass: "time" }, [
                                  _vm._v(_vm._s(item.created)),
                                ]),
                              ]),
                              _c("p", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.computedDescription(item)
                                  ),
                                },
                              }),
                            ]),
                          ],
                          1
                        )
                      }),
                      _vm.trendList.length == 0
                        ? _c("div", { staticClass: "empty" }, [
                            _vm._v(
                              "\n                            暂无数据\n                        "
                            ),
                          ])
                        : _vm._e(),
                      _vm.showMore
                        ? _c(
                            "div",
                            {
                              staticClass: "news-bottom",
                              on: { click: _vm.handleShowMore },
                            },
                            [_vm._m(0)]
                          )
                        : _vm._e(),
                    ],
                    2
                  ),
                ]
              ),
            ]),
          ],
          1
        ),
      ]),
      _c("create-project-dialog", {
        ref: "createProjectDialog",
        on: {
          "refresh-project-list": _vm.clearAndRefresh,
          aboutThisProject: _vm.aboutThisProject,
        },
      }),
      _c("el-tooltip", {
        ref: "tooltip",
        attrs: { effect: "black", placement: "top", content: "哈哈哈" },
      }),
      _vm.isEditProgram
        ? _c("edit-project", {
            attrs: { projectParams: _vm.projectParams },
            on: { "edit-program-finish": _vm.editProgramFinish },
          })
        : _vm._e(),
      _vm.isAddGoal
        ? _c("add-program-goal", {
            attrs: { goalParams: _vm.goalParams, baseData: _vm.baseData },
            on: { "add-goal-finish": _vm.addGoalFinish },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "news-show-more" }, [
      _vm._v("加载更多"),
      _c("i", { staticClass: "el-icon-d-arrow-right" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }