<template>
    <div class="project-search">
        <el-form
            ref="projectSearchForm"
            class="project-search-form"
            :model="projectSearchForm">
            <el-form-item>
                <el-input
                    v-model="projectSearchForm.keywords"
                    :maxlength="128"
                    placeholder="输入项目名或部门名等关键词，支持全文搜索"
                    class="search-input search-input-key"
                    @keyup.enter.native="(ev) => addParams()"
                ></el-input>
            </el-form-item>
            <el-form-item class="input-inner-label">
                <span class="form-input-label">客户</span>
                <customer-select
                    ref="customerSelect"
                    placeholder="搜索客户简称/全称"
                    @customerSelected="changeCustomer"
                ></customer-select>
            </el-form-item>
            <el-form-item class="input-inner-label" v-show="false">
                <span class="form-input-label">职位类别</span>
                <job-series-select
                    ref="jobSeriesSelect"
                    placeholder="搜索职位类别名称"
                    @jobSeriesSelected="changeJobSeries"
                ></job-series-select>
            </el-form-item>
            <el-form-item class="input-inner-label">
                <span class="form-input-label">拥有者</span>
                <el-popover
                    popper-class="team-member-popover"
                    placement="bottom-start"
                    width="700"
                    trigger="click"
                    v-model="teamMemberPopoverVisible"
                >
                    <team-member-select
                        ref="teamSelect"
                        :isAllMembers="true"
                        :isShowDimission="false"
                        :visible="teamMemberPopoverVisible"
                        :selected="projectSearchForm.ownerIds"
                        @item-select-cb="membersSelect"
                    ></team-member-select>
                    <el-input
                        slot="reference"
                        :readonly="true"
                        class="search-input has-team-select"
                        v-model="creators"
                    ></el-input>
                </el-popover>
            </el-form-item>
            <el-form-item class="input-inner-label">
                <span class="form-input-label create-time-label">创建时间</span>
                <el-date-picker
                    align="left"
                    size="small"
                    type="daterange"
                    format="yyyy-MM-dd"
                    range-separator="-"
                    class="search-input"
                    v-model="createdTime"
                    @change="changeCreatedTime"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    :picker-options="projectCreateTimeOption"
                    popper-class="job-create-time-range"
                ></el-date-picker>
            </el-form-item>
            <el-form-item class="input-inner-label">
                <el-radio-group v-model="projectSearchForm.isAssociateMe" @change="changeSearchProjectType">
                    <el-radio :label="false">查看全部</el-radio>
                    <el-radio :label="true">我参与的</el-radio>
                </el-radio-group>
            </el-form-item>
        </el-form>
        <div class="search-param-container" v-if="isShowSearchCondition">
            <div class="search-param-list">
                您的搜索结果数：
                <el-tag
                    closable
                    effect="plain"
                    class="search-param-item"
                    v-for="(search, index) in searchConditionList"
                    :key="index"
                    @close="removeLabel(search)"
                    v-show="search.value">
                    {{search.text}}: {{search.value}}
                </el-tag>
                <span class="job-total">
                    共 <span class="text-primary">{{projectNumber | currency('', 0)}}</span> 个项目
                </span>
                <span class="clear-search text-primary" @click="handleClearSearch">
                    <i class="el-icon-delete"></i>
                    清空搜索条件
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import TeamMemberSelect from '#/component/common/team-member-select.vue';
import CustomerSelect from '#/component/common/customer-select.vue';
import JobSeriesSelect from '#/component/common/job-series-select.vue';
import moment from 'moment'

const defaultSearchLabels = {
    keywords: {
        name: "keywords",
        title: "关键词",
        value: ""
    },
    customer: {
        name: "customer",
        title: "客户",
        value: "",
        textValue: ""
    },
    jobSeries: {
        name: "jobSeries",
        title: "职位类别",
        value: "",
        textValue: ""
    },
    creator: {
        name: "creator",
        title: "拥有者",
        value: ""
    },
    createDate: {
        name: "createDate",
        title: "创建时间",
        value: ""
    },
    locations: {
        name: "locations",
        title: "城市",
        value: "",
        textValue: ""
    },
};

export default {
    props: {
        projectNumber: {
            type: Number,
            default: 0
        }
    },
    components: {
        TeamMemberSelect,
        CustomerSelect,
        JobSeriesSelect
    },
    data() {
        return {
            teamMemberPopoverVisible: false,
            projectSearchForm: {
                isAssociateMe: false,
                keywords: "",
                customerIds: [],
                jobSeries: [],
                ownerIds: [],
                startDateTime: "",
                endDateTime: "",
                locations: []
            },
            creators: "全部成员",
            createdTime: "",
            projectCreateTimeOption: {
                disabledDate(date) {
                    return date.getTime() > Date.now();
                },
                shortcuts: [{
                    text: '今天',
                    onClick(picker) {
                        const end = moment().subtract(0, 'days').format('YYYY-MM-DD');
                        const start = moment().subtract(0, 'days').format('YYYY-MM-DD');
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '昨天',
                    onClick(picker) {
                        const end = moment().subtract(1, 'days').format('YYYY-MM-DD');
                        const start = moment().subtract(1, 'days').format('YYYY-MM-DD');
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近7天',
                    onClick(picker) {
                        const end = moment().subtract(0, 'days').format('YYYY-MM-DD');
                        const start = moment().subtract(6, 'days').format('YYYY-MM-DD');
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近30天',
                    onClick(picker) {
                        const end = moment().subtract(0, 'days').format('YYYY-MM-DD');
                        const start = moment().subtract(29, 'days').format('YYYY-MM-DD');
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '本月',
                    onClick(picker) {
                        const start = moment().add('month', 0).format('YYYY-MM') + '-01';
                        const end = moment(start).add('month', 1).add('days', -1).format('YYYY-MM-DD');
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '上月',
                    onClick(picker) {
                        const start = moment().subtract(1, 'month').format('YYYY-MM') + '-01';
                        const end = moment(start).subtract(-1, 'month').add('days', -1).format('YYYY-MM-DD');
                        picker.$emit('pick', [start, end]);
                    }
                }]
            },
            customerName: "",
            jobSeriesNames: "",
            searchLabels: JSON.parse(JSON.stringify(defaultSearchLabels)),
            searchConditionList: []
        }
    },
    computed: {
        isShowSearchCondition() {
            if(this.searchConditionList && this.searchConditionList.length > 0) {
                let showResult = this.searchConditionList.some(item => {
                    return item.value;
                })
                return showResult;
            } else {
                return false;
            }
        }
    },
    mounted() {
    },
    methods: {
        changeSearchProjectType(val) {
            // console.log(val)
            this.addParams();
        },
        addParams() {
            let searchList = this.projectSearchForm,
                searchLabelList = this.searchLabels,
                selectedItem = {};
            Object.keys(searchList).forEach(item => {
                switch(item) {
                    case 'isAssociateMe':
                    case 'endDateTime':
                    case 'locations':
                    case 'customerIds':
                    case 'jobSeries':
                        break;
                    case 'keywords':
                        if(/^\s+$/.test(searchList[item])) {
                            searchList[item] = searchList[item].trim();
                        }
                        searchLabelList[item].value = searchList[item];
                        break;
                    case 'ownerIds':
                        searchLabelList.creator.value = this.creators === '全部成员' ? '' : this.creators;
                        break;
                    case 'startDateTime':
                        searchLabelList.createDate.value = searchList.startDateTime ? searchList.startDateTime + '至' + searchList.endDateTime : "";
                        break;
                    default:
                        searchLabelList[item].value = searchList[item];
                }
            })
            this.updateSearchLabel();
            this.$emit('refresh-project-list');
        },
        changeCustomer(customer) {
            this.projectSearchForm.customerIds = [];
            if(customer.customerId) {
                this.projectSearchForm.customerIds.push(customer.customerId);
                this.searchLabels.customer.value = this.projectSearchForm.customerIds;
                this.searchLabels.customer.textValue = customer.customerName;
            }else {
                this.projectSearchForm.customerIds = [];
                this.searchLabels.customer.value = '';
                this.searchLabels.customer.textValue = '';
            }
            this.addParams();
        },
        changeJobSeries(jobSeries) {
            this.projectSearchForm.jobSeries = jobSeries.jobSeriesId ? [jobSeries.jobSeriesId] : [];
            this.searchLabels.jobSeries.value = jobSeries.jobSeriesId ? [jobSeries.jobSeriesId] : '';
            this.searchLabels.jobSeries.textValue = jobSeries.jobSeriesName;
            this.addParams();
        },
        changeCreatedTime(time) {
            if(time) {
                this.createdTime = time;
                this.projectSearchForm.startDateTime = moment(time[0]).format('YYYY-MM-DD');
                this.projectSearchForm.endDateTime = moment(time[1]).format('YYYY-MM-DD');
            } else {
                this.projectSearchForm.startDateTime = "";
                this.projectSearchForm.endDateTime = "";
            }
            this.addParams();
        },
        membersSelect(value, text) {
            this.creators = text;
            this.projectSearchForm.ownerIds = value;
            this.teamMemberPopoverVisible = false;
            this.addParams();
        },
        removeLabel(param) {
            let search = this.projectSearchForm;
            if(this.searchLabels.hasOwnProperty(param.title)) {
                this.searchLabels[param.title].value = "";
                if(this.searchLabels[param.title].hasOwnProperty('textValue')) {
                    this.searchLabels[param.title].textValue = "";
                }
            }
            switch(param.title) {
                case 'customer':
                    this.$refs.customerSelect.handleCustomerChange({
                        customerName: "",
                        customerId: ""
                    });
                    search.customerIds = [];
                    this.addParams();
                    break;
                case 'creator':
                    this.creators = "全部成员";
                    this.$nextTick(()=> {
                        this.$refs.teamSelect.handleClear();
                    })
                    this.projectSearchForm.ownerIds = [];
                    this.addParams();
                    break;
                case 'createDate':
                    this.createdTime = null;
                    this.changeCreatedTime();
                    break;
                case 'jobSeries':
                    search.jobSeries = [];
                    this.$refs.jobSeriesSelect.handleJobSeriesChange({
                        name: "",
                        id: ""
                    });
                    // this.addParams();
                    break;
                case 'locations':
                    search.locations = [];
                    this.addParams();
                    break;
                default:
                    search[param.title] = "";
                    this.addParams();
            }
        },
        updateSearchLabel() {
            let list = [];
            Object.values(this.searchLabels).forEach(label => {
                if(label.textValue || label.value) {
                    list.push({
                        title: label.name,
                        text: label.title,
                        value: label.textValue || label.value
                    })
                }
            });
            this.searchConditionList = list;
        },
        handleClearSearch() {
            this.customerName = "";
            this.jobSeriesNames = "";
            this.creators = "全部成员";
            this.createdTime = null;
            this.$nextTick(()=> {
                this.$refs.customerSelect.customerId = "";
                this.$refs.jobSeriesSelect.jobSeriesId = "";
                this.$refs.jobSeriesSelect.jobSeriesName = "";
                this.$refs.teamSelect.handleClear();
            })
            let params = {
                keywords: "",
                jobSeries: [],
                locations: [],
                customerIds: [],
                ownerIds: [],
                startDateTime: "",
                endDateTime: ""
            };
            Object.assign(this.projectSearchForm, params);
            this.searchLabels = JSON.parse(JSON.stringify(defaultSearchLabels));
            this.addParams();
        },
    }
};
</script>

<style lang="scss" scope>
.project-search {
    position: relative;
    .text-primary {
        color: $primary;
    }
    .firm-tab-menu.el-menu {
        border-bottom: none;
        .firm-tab-item.el-menu-item {
            height: 40px;
            line-height: 40px;
            max-width: 180px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            &.is-active {
                color: $primary !important;
                border-bottom: none;
                background-color: #f8f8f8;
            }
        }
    }
    .project-search-form {
        position: relative;
        padding: 24px 12px;
        // background-color: #f8f8f8;
        margin-bottom: 20px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .el-form-item {
            width: 100%;
            // width: 380px;
            margin-bottom: 20px;

            .customer-select-input .el-input__inner {
                height: 42px!important;
            }

            &.input-inner-label {
                width: 380px;
                .el-form-item__content {
                    position: relative;
                }
                .el-radio__input.is-checked + .el-radio__label {
                    color: #36B495 !important;
                }
            }
            &.input-outer-label {
                // height: 26px;
                .el-form-item__label {
                    margin-right: 5px;
                    margin-bottom: 0;
                    width: 80px;
                    color: #666;
                    padding-right: 0;
                    font-size: 14px;
                    line-height: 26px;
                    font-weight: bold;
                }
                .el-form-item__content {
                    line-height: 26px;
                    padding-left: 85px;
                    // height: 26px;
                }
            }
        }
        .form-input-label {
            height: 40px;
            width: 80px;
            text-align: center;
            display: inline-block;
            position: absolute;
            z-index: 1;
            top: 1px;
            left: 1px;
            white-space: nowrap;
            background: #fff;
            line-height: 40px;
            font-size: 14px;
            color: #333;
            border-radius: 4px;
            &::after {
                content: "";
                display: inline-block;
                width: 1px;
                height: 24px;
                background: #ddd;
                position: absolute;
                right: 0;
                top: 9px;
            }
        }
        .customer-select-input, 
        .job-series-select-input,
        .search-input {
            width: 100%;
            height: 42px;
            .el-input__inner {
                height: 42px;
                line-height: 42px;
                padding-right: 20px;
                padding-left: 92px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            &.search-input-key {
                width: 100%;
                .el-input__inner {
                    padding-left: 20px;
                }
            }
            &.el-date-editor {
                padding: 0;
                padding-left: 92px;
                line-height: 42px;
                .el-icon-date {
                    display: none;
                }
                .el-range-separator {
                    line-height: 40px;
                }
                .el-range__close-icon {
                    margin-left: 15px;
                    line-height: 40px;
                }
            }
        }
        .el-radio-group.search-radio-group {
            // height: 26px;
            .el-radio {
                line-height: 25px;
                padding: 0 10px;
                margin-right: 5px;
                margin-bottom: 5px;
                border-radius: 2px;
                &:hover {
                    background: #e9e9e9;
                }
                &.is-checked {
                    background: $primary;
                    &:hover {
                        background: $primary;
                    }
                    .el-radio__label {
                        color: #fff;
                    }
                }
                .el-radio__input {
                    display: none;
                }
                .el-radio__label {
                    padding-left: 0;
                }
            }
        }
        .more-job-location-btn {
            display: inline-block;
            line-height: 26px;
            padding: 0 10px;
            margin-right: 5px;
            color: $primary;
            cursor: pointer;
            &:hover {
                background: #e9e9e9;
            }
        }
        .more-btn {
            position: absolute;
            bottom: 20px;
            right: 20px;
            .icon-font {
                font-size: 14px;
                color: $primary;
                cursor: pointer;
            }
            &:hover, &:focus {
                .icon-font {
                    color: #48CAAC;
                    text-decoration: underline;
                }
            }
            &:active {
                .icon-font {
                    color: #36B495;
                    text-decoration: underline;
                }
            }
        }
    }
    .project-search-bottom {
        min-height: 32px;
        .project-search-select {
            display: inline-block;
            line-height: 32px;
            .project-search-dropdown-title {
                color: #666;
            }
            .project-search-dropdown.el-dropdown {
                .el-dropdown-link {
                    color: #b9b9b9;
                }
            }
            &.project-search-select-jobSource {
                margin-right: 28px;
            }
            &.project-search-select-orderBy {
                margin-left: 28px;
                float: right;
            }
        }
        .job-list-sum {
            float: right;
            line-height: 32px;
        }
    }
    .search-param-container {
        margin-bottom: 20px;
        background: #fff;
        .search-param-list {
            background: #f6f6f6;
            padding: 8px 20px 0;
            .search-param-item.el-tag {
                max-width: 815px;
                white-space: normal;
                line-height: 24px;
                height: auto;
                margin-right: 15px;
                margin-bottom: 8px;
            }
            .job-total {
                display: inline-block;
                margin-bottom: 8px;
            }
            .clear-search {
                display: inline-block;
                margin-bottom: 8px;
                margin-left: 20px;
                cursor: pointer;
            }
        }
    }
}
</style>

<style lang="scss">
    .project-search-dropdown-menu.el-dropdown-menu {
        margin-top: 0;
        padding: 0;
        .popper__arrow {
            display: none;
        }
        .el-dropdown-menu__item.active {
            background-color: #ebf8f5;
            color: $primary;
        }
    }
    .city-select-dialog.el-dialog {
        .el-dialog__header {
            display: none;
        }
        .el-dialog__body {
            position: relative;
        }
        .city-select-dialog-close {
            position: absolute;
            right: 20px;
            top: 16px;
            cursor: pointer;
            font-weight: bold;
            font-size: 18px;
            color: #b9b9b9;
            &:hover {
                color: #cf0224;
            }
        }
    }
</style>
