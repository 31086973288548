var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "project-search" },
    [
      _c(
        "el-form",
        {
          ref: "projectSearchForm",
          staticClass: "project-search-form",
          attrs: { model: _vm.projectSearchForm },
        },
        [
          _c(
            "el-form-item",
            [
              _c("el-input", {
                staticClass: "search-input search-input-key",
                attrs: {
                  maxlength: 128,
                  placeholder: "输入项目名或部门名等关键词，支持全文搜索",
                },
                nativeOn: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return ((ev) => _vm.addParams()).apply(null, arguments)
                  },
                },
                model: {
                  value: _vm.projectSearchForm.keywords,
                  callback: function ($$v) {
                    _vm.$set(_vm.projectSearchForm, "keywords", $$v)
                  },
                  expression: "projectSearchForm.keywords",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { staticClass: "input-inner-label" },
            [
              _c("span", { staticClass: "form-input-label" }, [_vm._v("客户")]),
              _c("customer-select", {
                ref: "customerSelect",
                attrs: { placeholder: "搜索客户简称/全称" },
                on: { customerSelected: _vm.changeCustomer },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: false,
                  expression: "false",
                },
              ],
              staticClass: "input-inner-label",
            },
            [
              _c("span", { staticClass: "form-input-label" }, [
                _vm._v("职位类别"),
              ]),
              _c("job-series-select", {
                ref: "jobSeriesSelect",
                attrs: { placeholder: "搜索职位类别名称" },
                on: { jobSeriesSelected: _vm.changeJobSeries },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { staticClass: "input-inner-label" },
            [
              _c("span", { staticClass: "form-input-label" }, [
                _vm._v("拥有者"),
              ]),
              _c(
                "el-popover",
                {
                  attrs: {
                    "popper-class": "team-member-popover",
                    placement: "bottom-start",
                    width: "700",
                    trigger: "click",
                  },
                  model: {
                    value: _vm.teamMemberPopoverVisible,
                    callback: function ($$v) {
                      _vm.teamMemberPopoverVisible = $$v
                    },
                    expression: "teamMemberPopoverVisible",
                  },
                },
                [
                  _c("team-member-select", {
                    ref: "teamSelect",
                    attrs: {
                      isAllMembers: true,
                      isShowDimission: false,
                      visible: _vm.teamMemberPopoverVisible,
                      selected: _vm.projectSearchForm.ownerIds,
                    },
                    on: { "item-select-cb": _vm.membersSelect },
                  }),
                  _c("el-input", {
                    staticClass: "search-input has-team-select",
                    attrs: { slot: "reference", readonly: true },
                    slot: "reference",
                    model: {
                      value: _vm.creators,
                      callback: function ($$v) {
                        _vm.creators = $$v
                      },
                      expression: "creators",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { staticClass: "input-inner-label" },
            [
              _c(
                "span",
                { staticClass: "form-input-label create-time-label" },
                [_vm._v("创建时间")]
              ),
              _c("el-date-picker", {
                staticClass: "search-input",
                attrs: {
                  align: "left",
                  size: "small",
                  type: "daterange",
                  format: "yyyy-MM-dd",
                  "range-separator": "-",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期",
                  "picker-options": _vm.projectCreateTimeOption,
                  "popper-class": "job-create-time-range",
                },
                on: { change: _vm.changeCreatedTime },
                model: {
                  value: _vm.createdTime,
                  callback: function ($$v) {
                    _vm.createdTime = $$v
                  },
                  expression: "createdTime",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { staticClass: "input-inner-label" },
            [
              _c(
                "el-radio-group",
                {
                  on: { change: _vm.changeSearchProjectType },
                  model: {
                    value: _vm.projectSearchForm.isAssociateMe,
                    callback: function ($$v) {
                      _vm.$set(_vm.projectSearchForm, "isAssociateMe", $$v)
                    },
                    expression: "projectSearchForm.isAssociateMe",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: false } }, [
                    _vm._v("查看全部"),
                  ]),
                  _c("el-radio", { attrs: { label: true } }, [
                    _vm._v("我参与的"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.isShowSearchCondition
        ? _c("div", { staticClass: "search-param-container" }, [
            _c(
              "div",
              { staticClass: "search-param-list" },
              [
                _vm._v("\n            您的搜索结果数：\n            "),
                _vm._l(_vm.searchConditionList, function (search, index) {
                  return _c(
                    "el-tag",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: search.value,
                          expression: "search.value",
                        },
                      ],
                      key: index,
                      staticClass: "search-param-item",
                      attrs: { closable: "", effect: "plain" },
                      on: {
                        close: function ($event) {
                          return _vm.removeLabel(search)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(search.text) +
                          ": " +
                          _vm._s(search.value) +
                          "\n            "
                      ),
                    ]
                  )
                }),
                _c("span", { staticClass: "job-total" }, [
                  _vm._v("\n                共 "),
                  _c("span", { staticClass: "text-primary" }, [
                    _vm._v(
                      _vm._s(_vm._f("currency")(_vm.projectNumber, "", 0))
                    ),
                  ]),
                  _vm._v(" 个项目\n            "),
                ]),
                _c(
                  "span",
                  {
                    staticClass: "clear-search text-primary",
                    on: { click: _vm.handleClearSearch },
                  },
                  [
                    _c("i", { staticClass: "el-icon-delete" }),
                    _vm._v("\n                清空搜索条件\n            "),
                  ]
                ),
              ],
              2
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }