<template>
    <div class="project-filter">
            <div class="project-filter-header">
                按搜索结果筛选：
            </div>
            <div v-if="filterObj && Object.keys(filterObj).length > 0">
                <filter-item
                    v-for="item in filterItems"
                    :key="item.title"
                    :sort-list="filterObj[item.filterTitle]"
                    :current-params="filterTransParams"
                    :multiple="item.multiple"
                    :title="item.title"
                ></filter-item>
            </div>
            <div class="project-filter-empty" v-else>暂无数据</div>
    </div>
</template>
<script>
import filterItem from './filter-item.vue'
import moment from 'moment'
export default {
    components: {
        filterItem,
    },
    data(){
        return{
            filterItems : [
                {
                    filterTitle: "location",
                    title: "locations",
                    multiple: true
                }, {
                    filterTitle: "jobSerie",
                    title: "jobSeries",
                    multiple: true
                }, {
                    filterTitle: "cutomer",
                    title: "customer",
                    multiple: true
                }
            ]
        }
    },
    props:{
        filterObj: {
            type: Object,
            default: {}
        },
        filterTransParams: {
            type: Object,
            default: {}
        }
    },
    mounted(){},
    methods: {
    }
}
</script>

<style lang="scss" scope>
.text-blue {
    color:  #4a90e2;
}
.project-filter {
    // border: 10px solid #f8f8f8;
    background: #fff;
    // padding: 0 10px 10px;
    padding: 0 20px 10px;
    border-radius: 8px;
    &-header {
        color: #333;
        font-weight: bold;
        height: 50px;
        line-height: 50px;
    }

    &-empty {
        height: 100px;
        line-height: 100px;
        text-align: center;
    }
}
</style>
