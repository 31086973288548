<template>
    <el-select
        class="job-series-select-input"
        popper-class="job-series-select"
        filterable
        clearable
        :disabled="isDisabled"
        v-model="jobSeriesName"
        :placeholder="placeholder"
        :filter-method="jobSeriesFilter"
        @clear="handleJobSeriesChange({
            id: '',
            name: ''
        })">
        <el-option
            v-for="jobSeries in jobSeriesFilterList"
            :key="jobSeries.id"
            :value="jobSeries.name"
            :label="jobSeries.name"
            :title="jobSeries.name"
            @click.native="handleJobSeriesChange(jobSeries)"
        ></el-option>
    </el-select>
</template>

<script>
import ProjectService from '#/js/service/projectService.js';
export default {
    name: "jobSeriesSelect",
    props: {
        isDisabled: {
            type: Boolean,
            default: false
        },
        placeholder: {
            type: String,
            default: "请选择职位类别"
        }
    },
    data() {
        return {
            jobSeriesId: '',
            jobSeriesName: '',
            jobSeriesFilterList: [],
            jobSeriesList: [],
        }
    },
    mounted() {
        this.getJobSeriesList();
    },
    methods: {
        getJobSeriesList() {
            ProjectService.getJobSeriesList()
            .then(res =>{
                this.jobSeriesList = res.jobSerieItems || [];
                this.jobSeriesFilterList = res.jobSerieItems || [];
            })
        },
        jobSeriesFilter(val) {
            if(val) {
                this.jobSeriesFilterList = this.jobSeriesList.filter(jobSeries => 
                    jobSeries.name.toLowerCase().indexOf(val.toLowerCase()) > -1
                )
            } else {
                this.jobSeriesFilterList = this.jobSeriesList
            }
        },
        handleJobSeriesChange(jobSeries) {
            this.jobSeriesId = jobSeries.id;
            this.jobSeriesName = jobSeries.name;
            this.$emit('jobSeriesSelected', {
                jobSeriesId: this.jobSeriesId, 
                jobSeriesName: this.jobSeriesName
            });
        },
        reset() {
            this.jobSeriesId = '';
            this.jobSeriesName = '';
            this.jobSeriesFilter();
        }
    }
}
</script>

<style lang="scss">
.job-series-select {
    max-width: 380px;
}
</style>
